<template>
  <div>
    <mia-navback @back="back" :isShowSave="!isView" @submit="submitForm" />
    <div class="header">
      <el-form :inline="true">
        <el-form-item label="台账编号:">
          {{ opinionLedger.no }}
        </el-form-item>
        <el-form-item v-if="opinionLedger.updateDate" label="修改时间:">
          {{ opinionLedger.updateDate }}
        </el-form-item>
        <el-form-item v-if="opinionLedger.updateUserName" label="最后修改人:">
          {{ opinionLedger.updateUserName }}
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <table>
        <caption style="font-size: 24px; font-weight: 600; margin-bottom: 20px">
          征求客户建议意见表
        </caption>
        <tbody>
          <tr>
            <td>客户姓名</td>
            <td style="text-align: left">
              <el-select
                v-model="opinionLedger.customerName"
                style="width: 100%"
                :disabled="isView"
                allowCreate
                filterable
                clearable
                placeholder="请选择"
                @change="customerChange"
              >
                <el-option
                  v-for="(item, Index) in customers"
                  :key="Index"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </td>
            <td>性别</td>
            <td>
              <el-radio-group
                v-model="opinionLedger.customerSex"
                :disabled="isView"
              >
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </td>
            <td>身份证号码</td>
            <td>
              <el-input
                style="width: 100%"
                v-model="opinionLedger.customerIdCard"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>住址</td>
            <td colspan="3" style="text-align: left">
              <el-input
                style="width: 100%"
                v-model="opinionLedger.customerAddress"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
            <td>联系电话</td>
            <td>
              <el-input
                style="width: 100%"
                v-model="opinionLedger.customerContact"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>客户建议意见</td>
            <td colspan="5" style="text-align: left">
              <el-input
                style="width: 100%"
                v-model="opinionLedger.customerOpinion"
                :disabled="isView"
                rows="10"
                type="textarea"
              ></el-input>
              <el-row style="margin-top: 20px">
                <el-col :span="12">
                  <span>记录人：</span>
                  <el-input
                    style="width: 200px"
                    v-model="opinionLedger.recordName"
                    :disabled="isView"
                    type="text"
                  ></el-input>
                </el-col>
                <el-col :span="12">
                  <span>时间：</span>
                  <el-date-picker
                    v-model="opinionLedger.recordDate"
                    :disabled="isView"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-col>
              </el-row>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Sign
      :visible="signatureVisible"
      @finsih="signFisish"
      @close="signatureVisible = false"
    ></Sign>
  </div>
</template>
<script>
import Sign from "@/components/Sign";
import { uploadFile } from "@/api/common";
import { imgUrl } from "../../../../settting";
import {
  getOpinionLedgerCode,
  getOpinionLedgerDetails,
  addOpinionLedger,
  editOpinionLedger,
} from "@/api/opinionLedger";
import { GetCustomerList } from "@/api/customer";
import moment from "moment";
export default {
  components: { Sign },
  data() {
    return {
      opinionLedger: {
        customerSex: "男",
      },
      checkPlace: [],
      isView: false,
      signatureVisible: false,
      customers: [],
    };
  },
  methods: {
    init(id, view) {
      this.getCustomerList();
      if (id) {
        getOpinionLedgerDetails(id).then((res) => {
          if (res.code === 1) {
            this.opinionLedger = res.data;
            this.isView = view;
          }
        });
      } else {
        getOpinionLedgerCode().then((res) => {
          if (res.code == 1) {
            this.opinionLedger = {
              ...this.opinionLedger,
              no: res.data,
              recordDate: moment().format("YYYY-MM-DD"),
            };
          }
        });
      }
    },
    getCustomerList() {
      GetCustomerList({ pageNum: 1, pageSize: 500 }).then((res) => {
        if (res.code == 1) {
          this.customers = res.data.records;
        }
      });
    },
    customerChange(val) {
      const results = this.customers.filter((v) => v.name == val);
      if (results.length > 0) {
        this.opinionLedger = {
          ...this.opinionLedger,
          customerIdCard: results[0].creditCode,
          customerContact: results[0].phone,
        };
      }
    },
    getImg(url) {
      return imgUrl + url;
    },
    valChange(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.value = val;
          }
          return v;
        });
      } else {
        if (val === "1") {
          this.safeItems = this.safeItems.map((v) => {
            if (name === v.name) {
              v.method = "0";
            }
            return v;
          });
        }
      }
    },
    val1Change(evt, name, val) {
      if (!evt) {
        this.safeItems = this.safeItems.map((v) => {
          if (name === v.name) {
            v.method = val;
          }
          return v;
        });
      }
    },
    back() {
      this.$emit("back");
    },
    checkPlaceChange(val) {
      this.checkPlace = val;
    },
    submitForm() {
      if (!this.opinionLedger.customerName) {
        return this.$message.error("请填写客户姓名");
      }
      if (!this.opinionLedger.customerIdCard) {
        return this.$message.error("请填写客户身份证号");
      }
      if (!this.opinionLedger.customerAddress) {
        return this.$message.error("请填写住址");
      }
      if (!this.opinionLedger.customerContact) {
        return this.$message.error("请填写联系电话");
      }
      if (!this.opinionLedger.customerOpinion) {
        return this.$message.error("请填写客户建议意见");
      }
      if (!this.opinionLedger.recordName) {
        return this.$message.error("请填写记录人");
      }
      if (this.opinionLedger.id) {
        editOpinionLedger(this.opinionLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        addOpinionLedger(this.opinionLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    openSign() {
      this.signatureVisible = true;
    },
    signFisish(val) {
      const imgs = this.base64ImgtoFile(val);
      const formData = new FormData();
      formData.append("file", imgs);
      uploadFile(formData).then((res) => {
        if (res.code === 1) {
          this.opinionLedger = { ...this.opinionLedger, sign: res.url };
        }
      });
      this.signatureVisible = false;
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 80%;
  margin: 0 auto;
  padding: 20px 50px;
  /deep/ .el-form-item {
    width: 32%;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff;
  color: #409eff;
}
.content {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
table {
  width: 100%;
}
.inline-table {
  border: 0px;
}
table,
td {
  border-collapse: collapse;
  border: 1px solid #999;
}
td {
  padding: 15px;
  font-size: 14px;
}
.sign {
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
}
</style>
